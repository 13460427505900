import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Start med å diskutere forslaget`}</h2>
    <p>{`Hvis du har laget et forslag til en forbedring eller en ny komponent, start gjerne med å diskutere løsningen i forum eller en av felleskanalene på Slack for å få innspill fra flere. For eksempel i felles designforum, frontend- og arkitekturforum eller Slack-kanalen #ext-design-general.`}</p>
    <p>{`Noen ganger er endringene så små at vi ikke trenger å snakke om dem i plenum først, men som hovedregel bør løsningsforslag diskuteres felles før de tas inn i designsystemet.`}</p>
    <h2>{`Hvis endringen bare er teknisk`}</h2>
    <p>{`Gjelder endringen din bare en teknisk endring og ikke nytt eller oppdatert design kan du foreslå den i form av en `}<a parentName="p" {...{
        "href": "https://github.com/SpareBank1/designsystem/pulls"
      }}>{`pull request til designsystem-repoet på GitHub`}</a>{`.`}</p>
    <p>{`Les mer om hvordan du lager en pull request bidrar til koden i `}<a parentName="p" {...{
        "href": "/bidra/guide-til-github/"
      }}>{`GitHub-guiden`}</a>{`.`}</p>
    <h2>{`Hvis designet blir nytt eller endret`}</h2>
    <p>{`Foreslår du en løsning som gjør at komponenten vil se annerledes ut, eller en helt ny komponent? Da må komponenten lages/oppdateres både i design-biblioteket i Figma og kode-biblioteket på GitHub.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Lag en branch med forslaget ditt i den aktuelle designsystem-fila i Figma og send review request til Ragnhild Lie, Sindre Säfvenbom og Anders Johnsen fra designsystem-teamet. Les mer om hvordan du bidrar i Figma i `}<a parentName="p" {...{
            "href": "/bidra/guide-for-figma/"
          }}>{`Figma-guiden`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Send en pull request med implementasjonen av forslaget til `}<a parentName="p" {...{
            "href": "https://github.com/SpareBank1/designsystem/"
          }}>{`designsystemet på GitHub`}</a>{`. Les mer om hvordan du bidrar til koden i `}<a parentName="p" {...{
            "href": "/bidra/guide-til-github/"
          }}>{`GitHub-guiden`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Designsystem-teamet kvalitetssikrer forslagene og publiserer dem ut til designsystemet`}</p>
      </li>
    </ul>
    <h2>{`Både design og kode må være på plass før publisering`}</h2>
    <p>{`Som hovedregel publiserer vi ikke endringer i designsystemet før vi har forslag både i Figma og på GitHub, med unntak av rene tekniske endringer. Dette er viktig for å holde designsystemet oppdatert og i synk på tvers av løsningene designere og utviklere bruker.`}</p>
    <p>{`Hvis du er designer kan du alliere deg med en eller flere utviklere på teamet ditt som kan kode opp forslaget du har laget i Figma, og lage en pull request til designsystemet på GitHub – og motsatt.`}</p>
    <p>{`Det går også an å komme med forslag bare på GitHub eller bare i Figma. Da må designsystem-teamet lage resten av forslaget når tid til å prioritere det opp. For å få løsningen raskt ut i designsystemet er det best å komme med forslag begge steder.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      